/* InviteGuest.css */

.react-time-picker {
  display: inline-block;
  border-radius: 0.25rem;
  border: 1px solid #cbd5e0;
  background-color: #fff;
  color: #2d3748;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-time-picker__inputGroup {
  display: flex;
  align-items: center;
}

.react-time-picker__inputGroup__divider {
  margin: 0 0.25rem;
}

.react-time-picker__clock {
  border: 1px solid #cbd5e0;
  box-shadow: none;
}

.react-time-picker__clockButton.react-time-picker__clockButton--disabled {
  color: #cbd5e0;
}

.react-time-picker__clockButton:hover {
  background-color: #edf2f7;
}

.react-time-picker__clock__hand {
  background-color: #4a5568;
}

.react-time-picker__clock__face {
  border: 1px solid #cbd5e0;
}

.react-time-picker__clock__number {
  color: #2d3748;
}

.react-time-picker__clock__number.react-time-picker__clock__number--selected {
  color: #fff;
  background-color: #2b6cb0;
}
