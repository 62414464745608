:root {
  --primary: rgb(7,47,100);
  --primaryDark: rgb(3, 23, 48);
  --secondary: #fc7067;
  --bgColor: #efe2ff;
  --bgColorLight: #f9f9f9;
  --lightPink: #fee8e7;
}

/* Hide the title bar */
html {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgb(7,47,100);
    /* overflow: hidden; */
}

.react-datepicker__input-container input {
  width: 98%;
  border: 1px solid #f8cecc;
  padding: 5px 0 5px 5px;
  border-radius: 5px;
  background: rgba(255, 238, 238, 0.474);
}

.highlighted-class {
  background-color: red;
}

.footer-wrapper .bottom-nav-link.active svg {
  /* color: #220142; */
  /* fill: black; */
  /* height: 30px;
  width: 30px; */
  transition: all ease-in-out 0.5s;
}

.footer-wrapper .bottom-nav-link.active {
  /* background-color: #3c1a5b32; */
  border-radius: 5px;
  /* transition: all ease-in-out 0.5s; */
  box-shadow: 2px 4px 38px -9px rgba(60, 26, 91, 0.75);
  -webkit-box-shadow: 2px 4px 38px -9px rgba(60, 26, 91, 0.75);
  -moz-box-shadow: 2px 4px 38px -9px rgba(60, 26, 91, 0.75);
}
.news-card-container {
  border-radius: 5px;
  overflow: hidden;
  width: 90%;
  position: relative;
}

.post_card_text_container {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 12px;
  transform: translate(0, -20%);
  margin: 0 10px 5px;
  overflow: hidden;
  border-radius: 5px;
  padding: 5px;
  background-color: rgba(33, 33, 33, 0.382);
  height: 80px;
  width: 80px;
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* line-clamp: 2; */
  -webkit-box-orient: vertical;
  color: var(--primaryDark);
  color: #fff;
  font-weight: 600;
  opacity: 1;
  font-size: 12px;
}

.errand-tab {
  flex: 1;
  border: "1px solid #BAB7B72E";
  display: flex;
  flex-direction: column;
  position: relative;
}

.errand-tab[aria-selected="true"]::after {
  content: "";
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-top: 0;
  border-bottom: 10px solid #f98b88;
  position: absolute;
  bottom: -11px;
}

.cofirm-payment-safe {
  position: absolute;
  top: -20;
  left: 0;
  width: 70px;
  height: 70px;
  background-color: var(--secondary);
  transform: translate(150%, -100%);
}
.plus {
  width: 40px;
  height: 40px;
  color: #fff;
  position: fixed;
  bottom: 0;
  right: 0;
  transform: translate(-150%, -350%);
}

@media (min-width: 470px) {
  .plus {
    transform: translate(-160%, -350%);
  }
}

@media (min-width: 530px) {
  .plus {
    transform: translate(-300%, -350%);
  }
}

.wallet-debit-card {
  background-color: "white";
  /* background: linear-gradient(121deg, #072f64 0%, #072f64 100%),
    linear-gradient(140deg, #ff5454 27%, #223dcb 100%),
    linear-gradient(140deg, #4e9c51 0%, #001aff 72%),
    linear-gradient(64deg, #6e00ff 0%, #0038ff 100%),
    radial-gradient(52% 101.79% at 50% 50%, #707300 0%, #072f64 100%),
    radial-gradient(100% 100% at 70% 0%, #7a3b00 0%, #d81313 100%);
  background-blend-mode: overlay, overlay, difference, difference, color-burn,
    exclusion; */
}

.special-request-plus {
  position: fixed;
  bottom: 0;
  right: 0;
  transform: translate(-80%, -200%);
}

.vehicle-payment-money {
  width: 80px;
  height: 80px;
  position: absolute;
  top: -80px;
}

.special-request-date-picker-wrapper .rmdp-input {
  height: 40px;
}
.vehicle .rmdp-container {
  width: 100%;
}
.vehicle .rmdp-input {
  width: 100%;
  height: 40px;
}

.category-container {
  width: 80px;
  /* height: 80px; */
}

@media (min-width: 350px) {
  .category-container {
    width: 65px;
  }
}
@media (min-width: 370px) {
  .category-container {
    width: 70px;
  }
}
@media (min-width: 393px) {
  .category-container {
    width: 75px;
  }
}
@media (min-width: 412px) {
  .category-container {
    width: 80px;
  }
}

.wallet-debit-card {
  /* background: linear-gradient(121deg, #072f64 0%, #072f64 100%),
    linear-gradient(140deg, #ff5454 27%, #223dcb 100%),
    linear-gradient(140deg, #4e9c51 0%, #001aff 72%),
    linear-gradient(64deg, #6e00ff 0%, #0038ff 100%),
    radial-gradient(52% 101.79% at 50% 50%, #707300 0%, #072f64 100%),
    radial-gradient(100% 100% at 70% 0%, #7a3b00 0%, #d81313 100%);
  background-blend-mode: overlay, overlay, difference, difference, color-burn,
    exclusion; */
  background-color: white;
  border-radius: 8px;
  height: 200px;
  width: 100%;
  color: black;
}

.estate-due-card {
  margin: 20px 20px 0px;
  padding: 20px;
  border-radius: 10px;
  background: linear-gradient(180deg, var(--primaryDark) 0%, #ffa7a7 100%),
    linear-gradient(244.35deg, #ffb26a 0%, #51bbc1 50.58%, #00a3ff 100%),
    linear-gradient(
      244.35deg,
      var(--primaryDark) 0%,
      var(--primaryDark) 49.48%,
      var(--primaryDark) 100%
    ),
    radial-gradient(
      100% 233.99% at 0% 100%,
      var(--primary) 0%,
      var(--primary) 100%
    ),
    linear-gradient(
      307.27deg,
      var(--primary) 0.37%,
      #687eb5 50.19%,
      #8877ce 100%
    ),
    radial-gradient(
      100% 140% at 100% 0%,
      var(--primary) 0%,
      var(--primary) 49.48%,
      var(--primaryDark) 100%
    );
  background-blend-mode: overlay, overlay, overlay, difference, color-dodge,
    normal;
}

.speech-bubble,
.tenant-speech-bubble {
  position: relative;
  background: #eee;
  border-radius: 0.4em;
  padding: 10px;
  font-size: 14px;
  width: 65%;
  font-weight: 600;
}

.tenant-speech-bubble {
  background: #ddd;
}

.speech-bubble:after {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-right-color: #eee;
  border-left: 0;
  border-bottom: 0;
  margin-top: -10px;
  margin-left: -10px;
  z-index: 0;
}

.tenant-speech-bubble:before {
  content: "";
  position: absolute;
  right: 0;
  top: 20px;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-left-color: #ddd;
  border-right: 0;
  border-top: 0;
  margin-top: -10px;
  margin-right: -10px;
}

.poll article div,
._is6ww {
  width: 100% !important;
}

@media print {
  .css-r5qt5c .css-18iegxu .css-qghp6r {
    display: none !important;
  }
}

/* Styles for hiding the address bar on different browsers */
@supports (-webkit-touch-callout: none) {
  body {
    height: calc(100vh + env(safe-area-inset-bottom));
  }
}

/* iOS Safari specific styles */
@media screen and (orientation: landscape) {
  body {
    height: calc(100vh - constant(safe-area-inset-bottom));
  }
}

/* Optional: Add more styles as needed */

.titleBar {
  display: none;
  position:relative;
  left: env(titlebar-area-x, 0);
  top: env(titlebar-area-y, 0);
  width: env(titlebar-area-width, 100%);
  height: env(titlebar-area-height, 0px);
  -webkit-app-region: drag;
  app-region: drag;
}
/* Adjust the width of the date pickers */
.picker-width {
  width: 100%;
}
